import React from 'react';
import styled from 'styled-components';

import { Link } from 'gatsby';

import Layout from '../../components/Layout.jsx';
import SEO from '../../components/Seo.jsx';
import Hero from '../../components/Hero.jsx';
import IllustratedText from '../../components/IllustratedText.jsx';
import Card, { Cards } from '../../components/Card.jsx';
import Quote from '../../components/Quote.jsx';
import { InfoBar } from '../../components/InfoSection.jsx';

import Money from '../../icons/Money.jsx';
import Schedule from '../../icons/Schedule.jsx';

import Planning from '../../icons/Planning.jsx';
import HolidaysPlanned from '../../icons/HolidayPlanned.jsx';
import CheckList from '../../icons/CheckList.jsx';
import Share from '../../icons/Share.jsx';
import Compare from '../../icons/Compare.jsx';
import Collaborate from '../../icons/Collaborate.jsx';
import Doctor from '../../icons/Doctor.jsx';
import Suit from '../../icons/Suit.jsx';

const Section = styled.div`
  padding: 50px 30px;

  &:nth-child(odd) {
    background-color: #f8f8f8;
  }
`;

const IndexPage = () => (
  <Layout main lang="fr">
    <SEO
      title="Horair.es"
      lang="fr"
      description="Vos horaires 7 jours sur 7 faciles"
      keywords={['horaire', 'planning', '24 / 7', '']}
    />
    <Hero lang="fr" openApp="Essayer" learnMore="Fonctionalités">
      Vos horaires 7 jours sur 7 faciles
    </Hero>
    <Section>
      <IllustratedText Illustration={Schedule}>
        <ul>
          <li>Votre équipe doit travailler toute la semaine, le jour et la nuit.</li>
          <li>Vous êtes la seule personne capable de maintenir votre feuille de calcul.</li>
          <li>Vos collaborateurs oublient quelle est la dernière version de votre planning.</li>
        </ul>
        <p>
          Horair.es vous aide à créer et gérer des <u>horaires complexes</u>.
        </p>
      </IllustratedText>
    </Section>
    <Section id="more-info">
      <Cards>
        <Card title="Créez votre planning" Illustration={Planning}>
          <p>Toutes les informations nécessaires au moment ou vous en avez besoin.</p>
        </Card>

        <Card title="Planifiez les vacances" Illustration={HolidaysPlanned}>
          <p>Distribuez les semaines de vacances avant de gérer le reste de l'horaire.</p>
        </Card>

        <Card title="Vérifiez votre travail" Illustration={CheckList}>
          <p>Assurez vous d'avoir été équitable entre vos collaboreurs.</p>
        </Card>

        <Card title="Partagez avec votre équipe" Illustration={Share}>
          <p>Tout le monde peut avoir accès à la dernière version du planning.</p>
        </Card>

        <Card title="Suivez les changements" Illustration={Compare}>
          <p>Vérifiez ce qui a changé entre différentes versions partagées.</p>
        </Card>

        <Card title="Collaborez" Illustration={Collaborate}>
          <p>Créez et éditez votre plan à plusieurs.</p>
        </Card>
      </Cards>
    </Section>
    <Section>
      <IllustratedText Illustration={Money} imageLeft>
        <ul>
          <li>Vous payez uniquement ce qui vous utilisez</li>
          <li>5 CHF (ou 4.5€) par personne par mois</li>
          <li>Toutes les fonctionalités inclues</li>
        </ul>
      </IllustratedText>
    </Section>
    <Section>
      <IllustratedText Illustration={Doctor} small>
        <Quote>
          Gérer mon équipe de 40 personnes n'est pas facile. Utiliser horair.es me simplifie la vie.
        </Quote>
        <Quote author="Cécile, Cheffe de clinique, CHUV Lausanne">
          Le fait de pouvoir accéder à horair.es en tout temps par natel et tablette je peux
          profiter des trajets en train pour avancer sur mes plannings.
        </Quote>
      </IllustratedText>
      <IllustratedText Illustration={Suit} imageLeft small>
        <Quote author="Anonyme">
          Ça me permet enfin de savoir lorsque ma compagne doit travailler de nuit.
        </Quote>
      </IllustratedText>
    </Section>
    <Section>
      <InfoBar>
        <Link to="/fr/docs">Documentation</Link>
        <a target="_blank" rel="noopener noreferrer" href="https://app.horair.es">
          Essayer maintenant
        </a>
        <a href="mailto:info@horair.es">Contactez nous</a>
      </InfoBar>
    </Section>
  </Layout>
);

export default IndexPage;
